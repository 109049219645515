import { ChangeEvent } from "react";
import { FormikErrors } from "formik";

export type APIError = {
  success: boolean;
  message: string;
};

export type Pagination = {
  offset?: number;
  limit?: number;
};

export type ListFilters = {
  filterBy: string;
  query: string;
  defaultQuery?: { [key: string]: string | number };
  [key: string]: any; // Permitir cualquier otro campo
};

export type Response<T> = {
  documents: T;
  success: boolean;
  totalDocuments: number;
  limit: number;
};

export enum Filters {
  CODE = "code",
  TABLE = "table",
  WAITER = "waiter",
  IS_ACTIVE = "isActive",
  QUERY = "query",
  NAMES = "names",
  NAME = "name",
  EMAIL = "email",
  INTERNAL_CODE = "internalCode",
  USER = "user",
  DELETED = "deleted",
  NUMBER = "number",
  CATEGORY = "category",
  CODE_SUNAT_COUNTRY = "codeSunat",
  BAR_CODE = "barCode",
  ESTABLISHMENTS = "establishments",
  PRODUCT = "product",
  STATE = "state",
  DEPARTMENT = "department",
}

export enum FilterTable {
  IS_BUSY = "busy",
  IS_FREE = "free",
  IS_BILLING = "bill",
}

export type Imagen = {
  public_id: string;
  url: string;
};

export type ProductParams = {
  establishments?: string;
  barCode?: string;
  name?: string;
  filterBy?: string;
  query?: string;
  [key: string]: any; // Permitir cualquier otro campo
};

export type EstablishmentsPriceList = {
  establishments: string;
  price: string;
  isDefault?: boolean;
};

export type PriceList = {
  unitTypesPresentations: string;
  description: string;
  factor: string;
  price: string;
  unitTypes?: string;
  establishments: string;
  _id: string;
};

export type SuppliesList = {
  supply: ProductSupplies;
  qty: number;
  unitType: UnitTypes;
  isOptional: boolean;
  isRequired: boolean;
  isExtra: boolean;
  isElegible: boolean;
  group: string;
};

export type GrupoKeys = "elegible1" | "elegible2" | "elegible3" | "elegible4" | "elegible5";

export type ProductSupplies = {
  suppliesList: any;
  _id: string;
  name: string;
  saleUnitPrice: number;
  type: string;
};

export type Establishments = {
  _id: string;
  codeSunat: string;
  name: string;
  isDefault?: boolean;
  defaultCustomer: string;
  country?: Country;
  department?: Department;
  province?: Province;
  district?: District;
  address?: string;
  tradeAddress?: string;
  email?: string;
  webAddress?: string;
  aditionalInformation: string;
  image?: Imagen;
  telephone: string;
};

export type AffectationIGVTypes = {
  _id: string;
  codeSunat: string;
  description: string;
};

export type CurrencyTypes = {
  _id: string;
  codeSunat: string;
  description: string;
  symbol: string;
};

export type UnitTypes = {
  _id: string;
  codeSunat: string;
  description: string;
};

export type OperationTypes = {
  _id: string;
  codeSunat: string;
  description: string;
};

export type Country = {
  _id: string;
  codeSunat: string;
  description: string;
};

export type Department = {
  _id: string;
  codeSunat: string;
  description: string;
};

export type ChargeDiscountAffectationTypes = {
  _id: string;
  codeSunat: string;
  type: string;
  level: string;
  cdp: string;
  description: string;
};

export type Province = {
  _id: string;
  codeSunat: string;
  description: string;
};

export type District = {
  _id: string;
  codeSunat: string;
  description: string;
};

export type Category = {
  _id: string;
  name: string;
};

export type SoapTypes = {
  _id: string;
  codeSunat: string;
  description: string;
};

export type IdentityDocumentTypes = {
  _id: string;
  codeSunat: string;
  maxlength: number;
  description: string;
};

export type User = {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
};

export type FormikTypeGeneral<T> = {
  errors: FormikErrors<T>;

  setErrors?: (fields: { [field: string]: string }) => void;
  values: T;
  handleChange(e: ChangeEvent<any>): void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<FormikErrors<T>> | Promise<void>;
};

export type CustomUnitType = {
  unitTypesPresentations: string;
  description: string;
  factor: string;
  establishments?: string;
  _id: string;
};

export type Production = {
  _id: string;
  name: string;
};
export type DataProductCart = {
  item: string;
  _id: string;
  name: string;
  price?: number;
  qty: number;
  subTotal: number;
  notes?: string;
  priceList?: string;
  imageUrl?: string;
  state: string;
  estado: string;
  comments: string;
  productionArea: string | Production;
  suppliesList: SuppliesList[];
};

export type ProductStock = {
  _id: string;
  establishment: string;
  product: string;
  currentQty: number;
  initQty: number;
};

export type ReceiptKT = {
  _id: string;
  comprobante: string;
};

export enum DocIdentyTypes {
  RUC = "6",
  CE = "4",
  PP = "D",
  CDI = "A",
  DNI = "1",
  DTNDSR = "0",
  PASS = "7",
  TAM = "E",
  TAX = "C",
  DIPR = "B",
}

export type ReceiptTypes = {
  _id: string;
  codeSunat: string;
  shortName: string;
  description: string;
};

export type Emisor = {
  receiptTypes: string;
  nroDoc: string;
  razonSocial: string;
  direccion: string;
  departments: string;
  province: string;
  districts: string;
  ubigeo: string;
  soap: string;
  soapTypes: string;
  establishments: string;
};
